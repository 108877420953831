<template>
  <el-dialog
    title="常见隐患"
    v-dialogDrag
    :visible.sync="sortSettingShow"
    class="avue-dialog main-box"
    width="60%"
  >
    <div class="flex-container">
      <CommonTreeNew
        treeTitle="隐患分类"
        node-key="id"
        isLeafIcons
        :defaultExpandedKeys="defaultExpandedKeys"
        ref="commonTreeCore"
        :treeData="corePersonnelTreeData"
        :defaultProps="defaultProps"
        @getNodeClick="corePersonnelTreeNodeClick"
        :showCheckbox="false"
        :treeExpand="false"
      />

      <div class="table-box">
        <head-layout head-title="隐患描述"></head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="corePersonnelTableOption"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          :table-data="tableData"
          :table-loading="TableLoading"
        >
          <template #customBtn="{ row }">
            <el-button type="text" size="small" @click="saveData(row)"
              >引入</el-button
            >
          </template>
        </grid-layout>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index.vue";
import Template from "@/views/message/template/list.vue";
import * as API from "@/api/safetyTtandard/sortSetting";
import GridLayout from "@/views/components/layout/grid-layout";
import { mapGetters } from "vuex";
export default {
  name: "index",
  components: { Template, GridLayout, CommonTree },
  computed: {
    ...mapGetters(["userInfo"]),
    corePersonnelTableOption() {
      let column = [
        {
          label: "隐患描述",
          prop: "content",
          align: "left",
          overHidden: true,
        },
      ];
      return {
        selection: false,
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: column,
      };
    },
  },
  data() {
    return {
      selectTree: undefined,
      TableLoading: false,
      sortSettingShow: false,
      corePersonnelTreeData: [],
      tableData: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        children: "children",
        label: "hdTypeName",
      },
      defaultExpandedKeys: [],
    };
  },
  async mounted() {},
  methods: {
    saveData(row) {
      this.$emit("callback", row);
      this.handleOpen();
    },
    corePersonnelTreeNodeClick(row) {
      this.selectTree = row;
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.page = page || this.page;
      this.tableLoading = true;
      API.getList(this.page.currentPage, this.page.pageSize, {
        ...params,
        ...this.query,
        organizationId: this.userInfo.dept_id,
        hdTypeId: this.selectTree.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    handleOpen() {
      this.sortSettingShow = !this.sortSettingShow;
      this.tableLoading = [];
    },
    initTree() {
      this.handleOpen();
      API.getTree({
        organizationId: this.userInfo.dept_id,
      }).then((res) => {
        this.corePersonnelTreeData = res.data.data;
        if (res.data.data.length != 0) {
          this.selectTree = res.data.data[0] || {};
          if (!this.selectTree.id) return;
          this.defaultExpandedKeys = [this.selectTree.id];
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.selectTree.id
            );
            this.$refs.commonTreeCore.nodeData = this.selectTree;
            this.corePersonnelTreeNodeClick(this.selectTree);
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.table-box {
  flex: 1;
}
::v-deep .common_tree_handle {
  .el-tree {
    height: 55vh !important;
    max-height: 55vh !important;
  }
}
::v-deep .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
